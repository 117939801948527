@import "@livingmap/hippo";

.toast {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);

  a {
    text-decoration: none;

    .closeButton {
      position: absolute;
      top: 24px;
      right: 24px;

      &:hover,
      &:active {
        position: absolute;
      }
    }
  }

  .adContainer {
    border-radius: 12px;
    border: 1px solid var(--outline);
    display: flex;
    padding: 12px;
    align-items: center;

    .image {
      width: 48px;
      height: 48px;
      background: var(--outline);
      border-radius: 500px;
      margin-right: 12px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 500px;
        object-fit: cover;
      }
    }

    .content {
      width: 66%;

      .text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .title {
        @include font-title-small;
        color: var(--primary);
      }

      .subtitle {
        @include font-label-medium;
        color: var(--secondary);
        margin-top: 5px;

        span {
          color: var(--location);
        }
      }
    }
  }
}
