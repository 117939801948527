@import "@livingmap/core-ui-v2";

.container {
  .status {
    @include font-body-medium;
  }

  .chevron {
    width: 12px;
    margin-left: 10px;
    color: $grey-tone-700;
  }

  .title {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    color: $grey-tone-900;
    @include font-body-medium;
  }

  .times {
    margin-top: 10px;
  }

  .daysInfo {
    border-collapse: collapse;
    margin-left: 40px;
    margin-top: 6px;
    @include font-body-medium;

    .active {
      @include font-body-medium-prominent;
    }

    tr:not(:last-child) {
      td {
        padding-bottom: 11px;
      }
    }

    span {
      margin-right: 40px;
      width: 74px;
    }

    td {
      vertical-align: top;

      div {
        unicode-bidi: plaintext;
      }

      div:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }
}
