@import "@livingmap/core-ui-v2";

.container {
  color: $grey-tone-900;
  padding: 20px 24px 24px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .closeIcon {
      cursor: pointer;
    }

    .headerText {
      @include font-headline-small;
      margin: 0;
    }
  }

  .qrCode {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 22px 0 32px;

    .svg {
      max-width: 160px;
      max-height: 160px;
    }

    .description {
      @include font-label-medium;
      margin: 20px 0 0;
    }
  }

  .footer {
    .urlContainer {
      border: 1px solid $grey-tone-300;
      border-radius: 8px;
      padding: 12px;
      margin: 0 0 12px;

      .url {
        @include font-body-medium;
        width: 100%;
        white-space: nowrap;
        overflow-x: scroll;
        margin: 0;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .button {
      justify-content: center;
      width: 100%;
    }
  }
}
